import Vue from 'vue'
import Vuex from 'vuex'
import defaultClient from "./modules/default-client";
import defaultEmployee from "./modules/default-employee";
import warehouse from "./modules/warehouse";
import organization from "./modules/organization";
import currency from "./modules/currency";
import ePos from "./modules/epos";
import account from "./modules/account";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        defaultClient,
        defaultEmployee,
        warehouse,
        organization,
        ePos,
        currency,
        account
    }
})
