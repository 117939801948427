<template>
    <b-overlay class="d-flex">
        <Sidebar/>
        <b-container fluid>
            <b-overlay :show="loading" variant="light">
                <router-view v-if="!loading"/>
            </b-overlay>
        </b-container>
    </b-overlay>
</template>

<script>
import Sidebar from "../../components/Sidebar";
import WarehouseService from "../../services/warehouse/warehouse.service";
import {mapGetters} from "vuex";

export default {
    name: "index",
    components: {Sidebar},
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            defaultClient: 'getDefaultClient',
            defaultEmployee: 'getDefaultEmployee',
            selectedWarehouse: 'selectedWarehouse'
        }),
    },
    methods: {
        async loadWarehouses() {
            let vm = this;
            let params = {
                skip: 0,
                limit: null,
                query: '',
                sort: null,
                desc: null
            };
            await vm.$store.dispatch('getAllWarehouses', params).then(function (warehouses) {
                if (warehouses.length === 0) {
                    localStorage.removeItem('selectedWarehouse');
                    vm.$store.commit('selectWarehouse', {warehouse: null});
                    /*vm.$notify("error", "Склад не найден", 'У вас нет ни одного склада', {
                        duration: 6000,
                        permanent: false
                    });*/
                    return;
                }
                if (vm.$route.query.warehouse) {
                    WarehouseService.get(+vm.$route.query.warehouse).then(warehouse => {
                        vm.$store.commit('selectWarehouse', {warehouse: warehouse});
                    });
                } else {
                    let selectWarehouse = null;
                    if (!localStorage.getItem('selectedWarehouse')) {
                        selectWarehouse = warehouses[0];
                    } else {
                        let selectedWarehouse = JSON.parse(localStorage.getItem('selectedWarehouse'));
                        let findInWarehouses = warehouses.find(warehouse => {
                            return selectedWarehouse.WarehouseId === warehouse.WarehouseId;
                        });
                        if (findInWarehouses)
                            selectWarehouse = selectedWarehouse;
                        else
                            selectWarehouse = warehouses[0];
                    }

                    //set to localstorage
                    vm.$store.commit('selectWarehouse', {warehouse: selectWarehouse});
                    localStorage.setItem('selectedWarehouse', JSON.stringify(selectWarehouse));
                }
            });
        },
    },
    async created() {
        this.loading = true;
        await this.loadWarehouses();
        await this.$store.dispatch("getDefaultClientFromApi").catch(err=>console.log(err));
        await this.$store.dispatch("getDefaultEmployee").catch(err=>console.log(err));
        await this.$store.dispatch("getTelegramDefaultClient").catch(err=>console.log(err));
        await this.$store.dispatch("loadOrganization").catch(err=>console.log(err));
        await this.$store.dispatch("getAllCurrencies", {skip: 0, limit: null, sort: null, desc: null}).catch(err=>console.log(err));
        this.loading = false;
    }
}
</script>

<style scoped>

</style>