import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import VueBarcodeScanner from 'vue-barcode-scanner';
Vue.use(VueBarcodeScanner);

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);
require("@/translation/translate.js");
Vue.config.productionTip = false
import "./filters";
import '../src/assets/scss/custom.scss';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
