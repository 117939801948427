<template>
    <div class="sidebar rounded-end bg-info">
        <div class="s-menu py-5">
            <ul class="list-unstyled">
                <li
                    v-for="(link, index) in links" :key="index"
                    :class="{isActive : $route.name === link.name}"
                    v-b-tooltip.hover.right :title="link.title"
                    @click="navigateTo(link)">
                    <b-icon
                        id="menu-links"
                        :icon="link.icon"
                        font-scale="1.2"
                        variant="light"
                        :class="{isActive : $route.name === link.name}">
                    </b-icon>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "Sidebar",
    data() {
        return {
            isActive: false,
            version: null,
            links: [
                {icon: 'house-door', route: '/app/sale/create', name: 'create-sale', title: "Новая продажа"},
                {icon: 'graph-up', route: '/app/sale/all', name: 'all-sales', title: "Все продажи"},
                {icon: 'box-seam', route: '/app/warehouse-item/all', name: 'all-warehouse-items', title: "Все товары"},
            ]
        }
    },
    methods: {
        navigateTo(link) {
            this.$router.push({name: link.name})
        }
    }
}
</script>

<style scoped>

</style>